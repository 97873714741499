import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalPopupService } from '../services/modalPopup.services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';

const dbincr = 0;
@Component({
  selector: 'app-globalmodalpopup',
  templateUrl: './globalmodalpopup.component.html',
  styleUrls: ['./globalmodalpopup.component.scss'],
})
export class GlobalmodalpopupComponent implements OnInit {
  // Loader & Error Handling Variables
  isLoading: boolean = false;
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';
  zoomLevel: number = 1;

  popupLoader: boolean = false;
  assertions: any = [];
  offset: number = 0;
  limit: number = 10;
  limitOptions: number[] = [10, 25, 50, 100];
  total: number = 0;
  currentPage: number = 0;
  noOfPages: number = 0;
  pages: any[] = [];
  pageStart: number = 0;
  pageLength: number = 5;
  pageEnd: number = this.pageStart + this.pageLength;
  searchQuery: string = '';
  downloadPath: string = environment.tqllcUpload;

  dialog: any;
  searchResponse: any = {};
  answerBox: string = '';
  sentVisualizationHTML: SafeHtml = '';
  statKeywords: any = [];
  statKeywordsArray: any = [];
  debunkprogress: any = 0;
  pvyProgressValue: any = 0;
  entities: any = [];
  searchStatistics: any = {};
  feedbackConclusion: string;
  reportConclusion: string;
  feedbackRating: string = '';
  selectedRating: number = 0;
  totalStars: number[] = [1, 2, 3, 4, 5];
  maxRating: number = 5;
  feedbackMeterScore: string = '';
  feedbackOften: string = '';
  feedbackPrice: string = '';
  showPagination: boolean = true;
  fallaciesData: any = [];
  sentenceData: any = [];
  syllogismConclusion: any = '';

  showFeedbackPopup = false;
  showWelcomePopup = false;
  showPartsPopup = false;
  showGeneralPopup = false;
  showGeneralAnalysisPopup = false;
  showEntitiesPopup = false;
  showCharacterCounterPopup = false;
  showAnswerBoxPopup = false;
  showReportBoxPopup = false;
  showAssertionPopup = false;
  showSyllogimsPopup = false;
  showFallaciesPopup = false;

  constructor(
    public dialogRef: MatDialogRef<GlobalmodalpopupComponent>,
    private modalPopupService: ModalPopupService,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    if (sessionStorage.getItem('query_response')) {
      this.searchResponse =
        JSON.parse(sessionStorage.getItem('query_response'))?.response || {};
    }
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('feedback') == 'true') {
      this.showFeedbackPopup = true;
    }
    if (sessionStorage.getItem('welcome') == 'true') {
      setTimeout(() => {
        this.showSyllogimsPopup = false;
        this.showFallaciesPopup = false;
      }, 0);
      this.showWelcomePopup = true;

      
      console.log('Welcome popup shown, syllogisms popup set to:', this.showSyllogimsPopup);
    }
    if (sessionStorage.getItem('parts_of_speech') == 'true') {
      setTimeout(() => {
        this.showSyllogimsPopup = false;
        this.showFallaciesPopup = false;
      }, 0);
      this.showPartsPopup = true;
      $('.global-modal-popup').addClass('parts-of-speech');
    }
    if (sessionStorage.getItem('general_definition') == 'true') {
      this.showGeneralPopup = true;
    }
    if (sessionStorage.getItem('general_analysis') == 'true') {
      this.showGeneralAnalysisPopup = true;
    }
    if (sessionStorage.getItem('entities') == 'true') {
      this.showEntitiesPopup = true;
    }
    if (sessionStorage.getItem('character_counter') == 'true') {
      this.showCharacterCounterPopup = true;
      $('.global-modal-popup').addClass('character');
    }
    if (sessionStorage.getItem('answer_box') == 'true') {
      this.showAnswerBoxPopup = true;
    }
    if (sessionStorage.getItem('report_bug') == 'true') {
      this.showReportBoxPopup = true;
    }
    if (sessionStorage.getItem('option') == 'assertion') {
      this.showAssertionPopup = true;
    }
    if (sessionStorage.getItem('option') == 'Syllogims') {
      this.showSyllogimsPopup = true;
      // this.sentenceData = JSON.parse(sessionStorage.getItem('syllogims')) || [];
      // this.showSyllogimsPopup = true;
      let syllogimsData = JSON.parse(sessionStorage.getItem('syllogims')) || [];
      let temp = [];

      console.log("Syllogisms data in popup modal: ");
      console.log(syllogimsData);

      if (syllogimsData.length > 0) {
        // Loop through the main array
        for (let item of syllogimsData) {
          // Check if the item is a non-empty array
          if (item.length > 0) {
            // Loop through each syllogism in the item
            for (let syllogism of item) {
              // Extract the premises and conclusion
              let premises = syllogism.premises || [];
              let conclusion = syllogism.conclusion || '';
              temp.push({
                premises: premises,
                conclusion: conclusion
              });
            }
          }
        }
      }

      this.sentenceData = temp;
      console.log(this.sentenceData);
    }
    if (sessionStorage.getItem('option') == 'Fallacies') {
      this.showFallaciesPopup = true;
      let temp = JSON.parse(sessionStorage.getItem('fallacies'));
      if (temp.length > 0) {
        for (let tt of temp) {
          tt['fallacy_score'] = parseFloat(tt['fallacy_score']);
        }
        console.log(temp);
        this.fallaciesData = temp;
      }
    }
    this.setResponseData();
  }

  closeModal() {
    this.modalPopupService.removePopup();
    // document.getElementsByClassName("animate__animated")[0].classList.remove("animate__bounceIn");
    // document.getElementsByClassName("animate__animated")[0].classList.add("animate__backOutLeft");

    setTimeout(() => {
      this.dialogRef.close();
    }, 200);
  }

  setResponseData() {
    this.answerBox = (
      this.searchResponse?.searchBoxResults ||
      'No answer found for this assertion'
    ).replace(/\n/g, '<br/>');
    this.sentVisualizationHTML = this.sanitizer.bypassSecurityTrustHtml(
      this.searchResponse?.sent_visualization || ''
    );
    this.statKeywords = new Set(this.searchResponse?.statistics?.keywords_List);
    this.pvyProgressValue =
      this.searchResponse?.statistics?.probabilityOfValidity;
    const pvyProgressVal =
      100 - this.searchResponse?.statistics?.probabilityOfValidity;
    this.debunkprogress = pvyProgressVal.toFixed(1).replace(/\.0$/, '');
    this.entities = this.searchResponse?.entities;
    this.searchStatistics = this.searchResponse?.statistics || {};

    this.getKeywordDef();
  }

  applySVGStyles() {
    const svgElements = document.querySelectorAll('.displacy');
    svgElements.forEach((svgElement: HTMLElement) => {
      svgElement.style.maxWidth = '100%';
      svgElement.style.height = 'auto';
    });
  }

  getKeywordDef(): void {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    this.statKeywordsArray = [];

    for (let keyword of this.statKeywords) {
      this.userService
        .getKeywordDef(keyword, userId, token)
        .subscribe((Response: any) => {
          if (Response.defs && Response.defs.length > 0) {
            let finalDef =
              Response.defs && Response.defs[0]
                ? Response.defs[0].split(';').reduce((acc, str) => {
                    str = str.trim();
                    acc.push(str.charAt(0).toUpperCase() + str.slice(1));
                    return acc;
                  }, [])
                : [];

            let temp = {
              keyword: keyword,
              definition: finalDef.slice(0, 3),
            };
            this.statKeywordsArray.push(temp);
          }
        });
    }
  }

  onChange(temp: any, val: any, key: any) {
    if (key === 'report_conclusion') {
      this.reportConclusion = temp.target.value;
    } else if (key === 'feedback_conclusion') {
      this.feedbackConclusion = temp.target.value;
    } else if (key === 'feedback_rating') {
      this.feedbackRating = this.getFeedbackLabel(val);
      this.selectedRating = this.getRatingValue(val);
    } else if (key === 'feedback_meter') {
      this.feedbackMeterScore = val;
    } else if (key === 'feedback_frequency') {
      this.feedbackOften = val;
    } else if (key === 'feedback_price') {
      this.feedbackPrice = val;
    } else {
      console.log('Invalid key');
    }
  }

  getFeedbackLabel(key: string): string {
    switch (key) {
      case 'very_satisfied':
        return 'Very Satisfied';
      case 'satisfied':
        return 'Satisfied';
      case 'neutral':
        return 'Neutral';
      case 'unsatisfied':
        return 'Unsatisfied';
      case 'very_unsatisfied':
        return 'Very Unsatisfied';
      default:
        return '';
    }
  }

  getRatingValue(key: string): number {
    switch (key) {
      case 'very_unsatisfied':
        return 1;
      case 'unsatisfied':
        return 2;
      case 'neutral':
        return 3;
      case 'satisfied':
        return 4;
      case 'very_satisfied':
        return 5;
      default:
        return 0;
    }
  }

  saveFeedback(): void {
    let userId = localStorage.getItem('userId');
    let userName = localStorage.getItem('userName');
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('access_token');

    let reqObj = {
      conclusion: this.feedbackConclusion,
      rating: this.feedbackRating,
      meter: this.feedbackMeterScore,
      frequency: this.feedbackOften,
      features_price: this.feedbackPrice,
      product_type: localStorage.getItem('plan_name'),
    };
    this.userService
      .saveUserFeedback(userName, email, reqObj, userId, token)
      .subscribe(
        (Response: any) => {
          if (Response.meta.code == 200) {
            this.openToaster(
              true,
              false,
              'Feedback form submitted successfully.'
            );
            setTimeout(() => {
              this.closeModal();
            }, 1000);
          } else if (Response.meta.code == 400) {
            this.openToaster(
              false,
              true,
              'Please fill all the fields to submit the feedback form.'
            );
          } else {
            this.openToaster(false, true, 'Failed to submit feedback form.');
          }
        },
        (error: any) => {
          if (error.status == 400) {
            this.openToaster(
              false,
              true,
              'Please fill all the fields to submit the feedback form.'
            );
          } else {
            this.openToaster(false, true, 'Failed to submit feedback form.');
          }
          console.log(error);
        }
      );
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }

    setTimeout(() => {
      // this.closeToaster();
    }, 2000);
  }

  getAssertions(download: boolean = false): void {
    this.isLoading = true;
    this.userService
      .getAssertionList(this.offset, this.limit, this.searchQuery, download)
      .subscribe((Response: any) => {
        this.isLoading = false;
        if (Response.meta.code == 200 && Response.data.assertions.length > 0) {
          this.assertions = Response.data.assertions;
          this.total = Response.data.totalRecords;
          this.noOfPages = Math.ceil(this.total / this.limit);
          if (this.total % this.total != 0) {
            this.noOfPages++;
          }

          this.pages = [];
          if (this.noOfPages < this.pageEnd) {
            this.pageEnd = this.noOfPages;
          }
          for (let i = this.pageStart; i < this.pageEnd; i++) {
            this.pages.push(i);
          }
          this.showPagination = true;
        } else {
          this.showPagination = true;
          this.assertions = [];
          this.total = Response.data.totalRecords
            ? Response.data.totalRecords
            : 0;
          this.noOfPages = 0;
          // this.openToaster(true, true, "Error", 'No Assertions Found');
        }
      });
  }
  zoomIn() {
    this.zoomLevel += 0.1; // Increase the zoom level
  }

  zoomOut() {
    if (this.zoomLevel > 0.2) {
      // Set a minimum zoom level
      this.zoomLevel -= 0.1; // Decrease the zoom level
    }
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }

  savereportbug() {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    let reqObj = {
      userId: userId,
      conclusion: this.reportConclusion,
      createdAt: new Date().toISOString().split('T')[0],
    };

    this.userService.saveReportedBug(reqObj, token).subscribe(
      (Response: any) => {
        if (Response.meta.code == 200) {
          this.openToaster(true, false, 'Reported bug successfully.');
          setTimeout(() => {
            this.closeModal();
          }, 1000);
        } else {
          this.openToaster(false, true, 'Failed to report bug');
        }
      },
      (error: any) => {
        this.openToaster(false, true, 'Failed to report bug');
        console.log(error);
      }
    );
  }

  getModalHeightClass(): string {
    const length = this.sentenceData.length;
  
    if (length < 2) {
      return 'syllogisms-modal-small';
    } else if (length < 5) {
      return 'syllogisms-modal-medium';
    } else if (length < 10) {
      return 'syllogisms-modal-large';
    } else if (length < 15) {
      return 'syllogisms-modal-xlarge';
    } else {
      return 'syllogisms-modal-default';
    }
  }
}
