<div *ngIf="isVisible" class="tutorial-modal-overlay">
  <div *ngIf="fullOverlay" class="full-overlay"></div>
  <div class="tutorial-modal" [ngClass]="'step' + (currentStepIndex + 1)">
    <div class="tutorial-header">
      <h2>{{ stepTitle }}</h2>
    </div>
    <div class="tutorial-body">
      <p>{{ stepContent }}</p>
    </div>
    <div class="tutorial-footer">
      <!-- Previous and Next buttons -->
      <div class="prev-next-buttons">
        <button *ngIf="currentStepIndex > 0" class="prev-next-button" (click)="previousStep()">Previous</button>
        <button *ngIf="currentStepIndex < tutorialService.getStepsCount() - 1" class="prev-next-button" (click)="nextStep()">Next</button>
      </div>
    
      <button class="skip-button" (click)="closeTutorial()">{{ currentStepIndex < tutorialService.getStepsCount() - 1 ? 'Skip tutorial' : 'Back to search page' }}</button>
    </div>
  </div>

  <div *ngIf="highlightPosition" class="highlight-box"
    [ngStyle]="{
      top: highlightPosition.top + 'px',
      left: highlightPosition.left + 'px',
      width: highlightPosition.width + 'px',
      height: highlightPosition.height + 'px'
    }"></div>
</div>