import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  rootData: any = {};

  private userIDSubject = new BehaviorSubject<string>(null);
  userID$ = this.userIDSubject.asObservable();

  private loginSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoggedIn$ = this.loginSubject.asObservable();

  private updateHistorySubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public updateHistory$ = this.updateHistorySubject.asObservable();

  private appLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public appLoading$ = this.appLoadingSubject.asObservable();

  private querySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public querySub$ = this.querySubject.asObservable();

  private dataSubject = new BehaviorSubject<{
    enableRanking: boolean;
    enableAuthor: boolean;
  }>({ enableRanking: false, enableAuthor: false });
  data$ = this.dataSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.rootData.backendUrl = environment.backendUrl;
    this.rootData.tqllcBackend = environment.tqllcBackend;
    this.rootData.tqllcFrontendUrl = environment.tqllcFrontendUrl;
    this.rootData.env = environment.env;
  }

  searchAnalysis(
    query: string,
    token: string,
    userId: string,
    beliefCheckStatus: boolean = false,
    stopWordStatus: boolean = false,
    spellCheckStatus: boolean = false,
    logicalFallacyStatus: boolean = false
  ): Observable<any> {
    let payload: any;
  
    // Check if errorSearchData exists in sessionStorage
    let resultsData = sessionStorage.getItem('errorSearchData');
    if (resultsData) {
      let resultsArray = JSON.parse(resultsData); // Parse the data into an array of objects
      // Search for a match in the results array
      const matchingResult = resultsArray.find((item: any) => {
        const sentenceNormalized = item.sentence.trim().toLowerCase();
        const queryNormalized = query.trim().toLowerCase();
      
        return (
          sentenceNormalized === queryNormalized &&
          item.results &&
          Object.keys(item.results).length > 0
        );
      });
      console.log("Matching Result:", matchingResult);
      // If a match is found, perform the same operations and bypass the API call
      if (matchingResult) {
        return new Observable((observer) => {
          // Simulate an API delay using setTimeout
          setTimeout(() => {
            if (matchingResult.results.query && matchingResult.results.resultCode == 1) {
              let searchResp = {
                title: query,
                response: matchingResult.results,
              };
              sessionStorage.setItem('searchQuery', query);
              sessionStorage.setItem('query_response', JSON.stringify(searchResp));
              sessionStorage.removeItem('query_error');
              matchingResult.results.statistics['isFromCache'] =
                matchingResult.results?.isFromCache == true ? true : false;
            } else {
              let searchError = {
                title: query,
                error: matchingResult.results,
              };
              sessionStorage.setItem('searchQuery', query);
              sessionStorage.setItem('query_error', JSON.stringify(searchError));
            }
            this.querySubject.next(true);
        
            // Notify observer of the matching result
            observer.next(matchingResult.results);
            observer.complete();
          }, 500); // Adjust delay as needed (e.g., 500ms for half a second)
        });
      }
    }
  
    // If not bypassing, proceed with the original logic
    let avoidCheckerList = [5, 4];
  
    if (beliefCheckStatus) {
      avoidCheckerList.push(1);
    }
    if (stopWordStatus) {
      avoidCheckerList.push(3);
    }
    if (spellCheckStatus) {
      avoidCheckerList.push(0);
    }
    if (logicalFallacyStatus) {
      avoidCheckerList.push(7);
    }
  
    if (this.rootData.env == 'dev-portal') {
      payload = {
        q: query,
        nc: false,
        ac: avoidCheckerList,
      };
    } else {
      payload = {
        q: query,
        nc: false,
        ac: avoidCheckerList,
      };
    }
  
    return this.http
      .post(
        this.rootData.backendUrl + userId + '/check_errors_search_post',
        payload,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.query && response.resultCode == 1) {
            let searchResp = {
              title: query,
              response: response,
            };
            sessionStorage.setItem('searchQuery', query);
            sessionStorage.setItem('query_response', JSON.stringify(searchResp));
            sessionStorage.removeItem('query_error');
            response.statistics['isFromCache'] =
              response?.isFromCache == true ? true : false;
          } else {
            let searchError = {
              title: query,
              error: response,
            };
            sessionStorage.setItem('searchQuery', query);
            sessionStorage.setItem('query_error', JSON.stringify(searchError));
          }
          this.querySubject.next(true);
        })
      );
  }
  

  getUserId(userId: string) {
    this.userIDSubject.next(userId);
    this.router.navigate(['']);
  }

  setStorageValue(type: string, key: string, value: string): any {
    if (!value) {
      return;
    }

    return type == 'local'
      ? localStorage.setItem(key, value)
      : sessionStorage.setItem(key, value);
  }

  removeAllItemsFromStorage(): void {
    return localStorage.clear();
  }

  logout() {
    this.removeAllItemsFromStorage();
    localStorage.removeItem('access_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
    localStorage.removeItem('mobile');
    localStorage.removeItem('profile_pic');
    window.location.href = this.rootData.tqllcFrontendUrl;
  }

  getUserDetails(userId: string): Observable<any> {
    localStorage.removeItem('isfreetrial');

    return this.http
      .post(this.rootData.tqllcBackend + 'token/createToken', {
        userId: userId,
      })
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            localStorage.setItem('access_token', response.data.myToken);
            localStorage.setItem('userId', response.data._id);
            localStorage.setItem('userName', response.data.name);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('mobile', response.data.mobile);
            localStorage.setItem(
              'subscription_details',
              JSON.stringify(response.data.subscription_details)
            );
            localStorage.setItem('isfreetrial', response.data.isFreeTrial);
            localStorage.setItem('profile_pic', response.data.profileImage);
            this.loginSubject.next(true);
          } else {
            this.loginSubject.next(false);
          }
        })
      );
  }

  getSubscriptionDetails(userId: string, token: string): Observable<any> {
    return this.http
      .post(
        this.rootData.tqllcBackend + 'subscription/get-subscriptionDataList',
        { userId: userId },
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            let allPlans = [
              'price_1PveMlD80yLHWHGlXLs6tmCs',
              'price_1PveiKD80yLHWHGlfPTbYwoZ',
              'price_1Pven5D80yLHWHGlTMV8VxJO',
              'price_1PvVSSD80yLHWHGla3BYToyA',
              'price_1PvVWqD80yLHWHGlNo2ovd33',
              'price_1PvVZsD80yLHWHGlVNQ75dnz',
            ];

            for (let res of response.data) {
              if (
                res.planId == 'price_1PveMlD80yLHWHGlXLs6tmCs' ||
                res.planId == 'price_1PvVSSD80yLHWHGla3BYToyA'
              ) {
                localStorage.setItem('plan_name', res.planName);
              }
              if (
                res.planId == 'price_1PveiKD80yLHWHGlfPTbYwoZ' ||
                res.planId == 'price_1PvVWqD80yLHWHGlNo2ovd33'
              ) {
                localStorage.setItem('plan_name', res.planName);
              }
              if (
                res.planId == 'price_1Pven5D80yLHWHGlTMV8VxJO' ||
                res.planId == 'price_1PvVZsD80yLHWHGlVNQ75dnz'
              ) {
                localStorage.setItem('plan_name', res.planName);
              }
            }

            localStorage.setItem('plan_name', response.data[0].planName);
          }
        })
      );
  }

  getUserHistoryCount(userId: string, token: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'searchHistory/searchHistoryUserCount?userId=' +
        userId,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getsyllogimsData(
    userId: string,
    token: string,
    paragraphs: string
  ): Observable<any> {
    const payload = {
      paragraphs: paragraphs,
    };
  
    return this.http.post(
      this.rootData.backendUrl + `${userId}/find_syllogisms_paragraphs`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  deleteUserDocument(
    userId: string,
    token: string,
    id: string
  ): Observable<any> {
    return this.http.delete(
      this.rootData.tqllcBackend + 'file/delete-document-by-id/' + id,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getUserDocumentsByUser(userId: string, token: string): Observable<any> {
    if (userId && token) {
      return this.http.get(
        this.rootData.tqllcBackend + 'file/get-documents-by-user/' + userId,
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      );
    }
    // return this.http.get(this.rootData.tqllcBackend + 'file/get-documents-by-user/660ed2c61199815d14fd7c5b', {
    //     headers: { 'Authorization': 'Bearer ' + token }
    // });
  }

  downloadDocumentsById(
    userId: string,
    token: string,
    id: string
  ): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend + 'file/download-document/' + id,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getUserDocumentsById(
    userId: string,
    token: string,
    id: string
  ): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend + 'file/get-document-by-id/' + id,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getSentencesById(
    userId: string,
    token: string,
    sentenceId: string
  ): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'file/get-sentences-by-document-id/' +
        sentenceId,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  getSearchSuggestions(token: string, search: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'cache/get-search-suggestions?search=' +
        search,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  setLoaderEvent(event: any) {
    if (event) {
      this.appLoadingSubject.next(true);
    } else {
      this.appLoadingSubject.next(false);
    }
  }

  sendErrorEmailReport(token: string) {
    return this.http
      .post(
        this.rootData.tqllcBackend + 'contact/sendError',
        {},
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            console.log('Error mail Triggered');
          }
        })
      );
  }

  saveSearchHistory(
    query: string,
    userId: string,
    token: string,
    filename: string = '',
    fileId: string = ''
  ): Observable<any> {
    return this.http
      .post(
        this.rootData.tqllcBackend + 'searchHistory/searchHistorySave',
        { searchKey: query, userId: userId },
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .pipe(
        tap((response: any) => {
          console.log(response);
          if (response.meta.code == 200) {
            this.updateHistorySubject.next(true);
          } else {
            this.updateHistorySubject.next(false);
          }
        })
      );
  }

  getHistory(userId: string, token: string): Observable<any> {
    if (token && userId) {
      return this.http.get(
        this.rootData.tqllcBackend +
          'searchHistory/searchHistoryListUserWise?userId=' +
          userId,
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      );
    }
  }

  removeMenuHistory(
    userId: string,
    token: string,
    query: string
  ): Observable<any> {
    return this.http
      .post(
        this.rootData.tqllcBackend + 'searchHistory/deleteHistoryUserWise',
        { searchKey: query, userId: userId },
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.meta.code == 200) {
            this.updateHistorySubject.next(true);
          } else {
            this.updateHistorySubject.next(false);
          }
        })
      );
  }

  getKeywordDef(
    keyword: string,
    userId: string,
    token: string
  ): Observable<any> {
    return this.http.get(
      this.rootData.backendUrl + userId + '/get_definition?q=' + keyword
    );
  }

  getPresizedURL(filename: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'file/get-presigned-url?fileName=' +
        filename +
        '&userId=' +
        localStorage.getItem('userId')
    );
  }

  sendAssertionFile(url: string, fileData: File): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': fileData.type,
    });

    return this.http.put(url, fileData, {
      headers,
      observe: 'response',
    });
  }

  sendPostData(fileId: string, userId: string): Observable<any> {
    return this.http.post(
      this.rootData.backendUrl + userId + '/process_document',
      { fileId: fileId },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  uploadPlainTextData(
    textData: string,
    userId: string,
    token: string
  ): Observable<any> {
    return this.http.post(
      this.rootData.tqllcBackend + 'file/upload_text',
      { text: textData, userId: userId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
  }

  saveUserFeedback(
    name: string,
    email: string,
    reqObj: any,
    userId: string,
    token: string
  ): Observable<any> {
    reqObj['userId'] = userId;
    reqObj['createdAt'] = new Date().toISOString();
    reqObj['email'] = email;
    reqObj['name'] = name;

    return this.http.post(
      this.rootData.tqllcBackend + 'contact/feedback',
      reqObj,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  saveReportedBug(reqObj: any, token: string): Observable<any> {
    return this.http.post(
      this.rootData.tqllcBackend + 'contact/report-bug',
      reqObj,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
  }

  // Cached APIs
  getVersion(): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend + 'cache/get-version?env=' + this.rootData.env,
      {}
    );
  }

  getAssertionList(
    offset: number,
    limit: number,
    search: string,
    download: boolean = false
  ): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend +
        'cache/get-assertions?offset=' +
        offset +
        '&limit=' +
        limit +
        '&search=' +
        search +
        '&download=' +
        download,
      {}
    );
  }

  getGoogleAnswer(id: string): Observable<any> {
    return this.http.get(
      this.rootData.tqllcBackend + 'cache/get-google-answers-id/' + id,
      {}
    );
  }

  deleteGoogleAnswer(id: string): Observable<any> {
    return this.http.delete(
      this.rootData.tqllcBackend + 'cache/delete-answers-by-id/' + id,
      {}
    );
  }

  updateAssertion(id: string, updateData: any): Observable<any> {
    return this.http.put(
      this.rootData.tqllcBackend + 'cache/update-assertions-by-id/' + id,
      updateData,
      {}
    );
  }

  createGoogleAnswer(updateData: any): Observable<any> {
    return this.http.post(
      this.rootData.tqllcBackend + 'cache/create-google-answers-id',
      updateData,
      {}
    );
  }

  updateGoogleAnswer(id: string, updateData: any): Observable<any> {
    return this.http.put(
      this.rootData.tqllcBackend + 'cache/update-google-answers-id/' + id,
      updateData,
      {}
    );
  }

  deleteAssertion(id: string): Observable<any> {
    return this.http.delete(
      this.rootData.tqllcBackend + 'cache/delete-assertions-by-id/' + id,
      {}
    );
  }
  setData(
    data: { enableRanking: boolean; enableAuthor: boolean },
    demoFlag: boolean
  ) {
    if (demoFlag) {
      localStorage.setItem('demo_data', 'true');
    } else {
      localStorage.setItem('demo_data', 'false');
    }
    this.dataSubject.next(data);
  }


  saveAssertion(guid: string, assertion: string, docId: string, userId: string) {
    console.log("Before hitting the save assertion api");
    console.log(guid);
    console.log(assertion.normalize("NFC"));
    console.log(docId);
    return this.http.post(
      this.rootData.backendUrl + userId + '/run_assertions_through_file_system',
      {   
        "fileId": docId,
        "assertions": [
            {
                "guid": guid,
                "updatedAssertionText": assertion.normalize("NFC")
            }
        ]
    },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  rerunAssertion(guid: string, assertion: string, docId: string, userId: string) {
    console.log("Before hitting the rerun assertion api");
    console.log(guid);
    console.log(assertion.normalize("NFC"));
    console.log(docId);
    return this.http.post(
      this.rootData.backendUrl + userId + '/run_assertions_through_file_system',
      {   
        "fileId": docId,
        "assertions": [
            {
                "guid": guid,
                "updatedAssertionText": assertion.normalize("NFC")
            }
        ]
    },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  rerunAllAssertions(assertionsArray: { guid: string; updatedAssertionText: string; }[], docId: string, userId: string) {
    console.log("Before hitting the rerun all assertions api");
    console.log(assertionsArray);
    console.log(docId);
    return this.http.post(
      this.rootData.backendUrl + userId + '/run_assertions_through_file_system',
      {   
        "fileId": docId,
        "assertions": assertionsArray
    },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }



}
