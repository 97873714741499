import { Component, OnInit, Input, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { ModalPopupService } from '../services/modalPopup.services';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalmodalpopupComponent } from '../globalmodalpopup/globalmodalpopup.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { TutorialService } from '../services/tutorial.service';

//probability of validity graph
const stbar1 = 0;
const stbar2 = 0;

//change of opinion graph
const truprogress = 36;
const ptvprogress = 86;
const flsprogress = 26;

declare var $: any;


@Component({
  selector: 'app-search-results-view',
  templateUrl: './search-results-view.component.html',
  styleUrls: ['./search-results-view.component.scss'],
})
export class SearchResultsViewComponent implements OnInit {
  // Assertions text editor
  // html$ = '';
  // get html(): string {
  //   return this.html$;
  // }

  // set html(html: string) {
  //   this.html$ = html;
  //   this.htmlContent = html.replace(/<p><\/p>/ig, '<p><br><\/p>');
  // }

  // Loader & Error Handling Variables
  isLoading: boolean = false;
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';
  @Input() truthMeter = 0;
  @Input() labelVal = 0;
  @Input() ranking: boolean;

  @Input() highlightedElement: number = 0;

  // Guage chart
  public canvasWidth;
  public needleValue;
  public counter;
  public centralLabel;
  public label;
  public options;
  public showcorrectionTooltip: boolean = false;

  private hideTimeout: any;

  // statics
  pvgprogress = 0;
  pvyprogress: any = 0;

  //chance of opinion
  trueprogress = 0;
  ptprogress = 0;
  falseprogress = 0;
  dialogRef: MatDialogRef<any>;

  graphType: string = 'TRUE';
  labelText: string = '';
  env: string = environment.env;
  htmlContent: SafeHtml;
  editingSentence: boolean = false;
  editedText: string = '';
  currentGuid: string = '';
  edtablehtmlContent: string;
  docId: string;
  documentData: any = {};
  sentenceData: any = {};
  corrections: number = 0;
  allAssertions: any = [];
  syllogims: any = [];
  documentScore: any = 50;

  tutorialActive = false;


  // percentageProcessed: number | null = null; // Initialize as null
  percentageProcessed: number = 0; // Initialize as null
  foundSyllogismsCount: number = 0;           // Initialize as 0
  syllogismsBackgroundClass: string = 'syllogisms-bg-0';

  assertionsArray: { guid: string; updatedAssertionText: string }[] = [];
  private eventListeners: (() => void)[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private modalPopupService: ModalPopupService,
    private dialog: MatDialog,
    private el: ElementRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer, // Import DomSanitizer
    private http: HttpClient,
    private tutorialService: TutorialService,
    private cdr: ChangeDetectorRef
  ) {

    this.userService.appLoading$.subscribe((appLoad) => {
      if (appLoad) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    });

    this.docId = '';
    this.docId = this.route.snapshot.queryParamMap.get('assertion')
      ? this.route.snapshot.queryParamMap.get('assertion')
      : null;
    // console.log(this.docId);
    localStorage.setItem('docId', this.docId);

    this.readDocumentById(this.docId);
    this.getSentenceById(this.docId);

    var style = document.createElement('style');
    // Add CSS rules to the style element
    style.innerHTML = `
    
        .truth-value-text{
            transition: transform 0.3s ease; /* Smooth transition effect */
            cursor: pointer;
            font-family: "Montserrat-regular", Arial, Helvetica, sans-serif !important;
            display: inline-block;
            text-indent: 2pt;
        }

        .truth-value-text:hover{
            transform: scale(1.02); /* Zoom in by 10% */
            background-color: #f3f3f3;
        }
          /* Style for the circle at the start of each paragraph */
          .paragraph-wrapper {
            display: flex;
            align-items: flex-start;
            margin-bottom: 1em;
            width: 100%;
          }
          
          .paragraph-score {
            // display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid #ececec;
            font-size: 12px;
            color: black;
            background-color: #fff;
            margin-right: 15px;
          }

          
`;

    // Append the style element to the <head> section
    document.head.appendChild(style);




    //  Assertions value with inner value
    // this.htmlContent = this.getInnerHTMLValue();
    this.sanitizer.bypassSecurityTrustHtml('this.htmlContent');
  }

  // getInnerHTMLValue() {
  //   this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
  // }

  //   Below is the hardcoded value for sampleHtmlContent. Note that you need to get it dynamically using api. I wrote comment already where you can get it.
  sampleHtmlContent = `<html>
        <head>
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
        <meta content="Microsoft Word 15 (filtered)" name="Generator"/>
        <style>
        <!--
        /* Font Definitions */
        @font-face
          {font-family:"Cambria Math";
          panose-1:2 4 5 3 5 4 6 3 2 4;}
        /* Style Definitions */
        p.MsoNormal, li.MsoNormal, div.MsoNormal
          {margin:0cm;
          line-height:115%;
          font-size:11.0pt;
          font-family:"Arial",sans-serif;}
        .MsoPapDefault
          {line-height:115%;}
        @page WordSection1
          {size:595.45pt 841.7pt;
          margin:72.0pt 72.0pt 72.0pt 72.0pt;}
        div.WordSection1
          {page:WordSection1;}
        -->
        </style>
        </head>
        <body lang="EN-US" style="word-wrap:break-word">
        </body>
        </html>
        `;

  ngOnInit(): void {
    const storedAssertions = localStorage.getItem('assertions');
    if (storedAssertions) {
      // Parse and assign the stored assertions
      this.assertionsArray = JSON.parse(storedAssertions);
      console.log(this.assertionsArray);
    }
    console.log(this.assertionsArray);

    setTimeout(() => {
      this.updateHtmlContent();
    }, 500);
    // this.oribabilityYellowGraph();
    // this.probabilityGreenGraph();
    // this.trueOpinonGraph();
    // this.ptOpinonGraph();
    // this.falseOpinonGraph();

    // Guage Chart
    this.canvasWidth = 230;
    this.needleValue = '0';
    this.counter = 0;
    this.centralLabel = '';
    this.options = {
      hasNeedle: true,
      needleColor: '#3B3743',
      needleUpdateSpeed: 3000,
      arcColors: [
        '#FF1919',
        '#FF231B',
        '#FF2C1C',
        '#FF361E',
        '#FF3F1F',
        '#FE4921',
        '#FE5322',
        '#FE5C24',
        '#FE6625',
        '#FE6F27',
        '#FE7928',
        '#FE822A',
        '#FE8C2B',
        '#FD962D',
        '#FD9F2E',
        '#FDA930',
        '#FDB231',
        '#FDBC33',
        '#FDC634',
        '#FDCF36',
        '#FDD937',
        '#FCE239',
        '#FCEC3A',
        '#FCF53C',
        '#FCFF3D',
        '#FCFF3D',
        '#F3FE3C',
        '#E9FD3C',
        '#E0FC3B',
        '#D7FB3A',
        '#CEFA39',
        '#C4F939',
        '#BBF838',
        '#B2F737',
        '#A8F636',
        '#9FF536',
        '#96F435',
        '#8DF334',
        '#83F233',
        '#7AF133',
        '#71F032',
        '#67EF31',
        '#5EEE30',
        '#55ED30',
        '#4BEC2F',
        '#42EB2E',
        '#39EA2D',
        '#30E92D',
        '#26E82C',
        '#1DE72B',
      ],
      arcDelimiters: [
        2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38,
        40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74,
        76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98,
      ],
      arcOverEffect: true,
      // rangeLabel: ['0', '100'],
      needleStartValue: 0,
      arcpadding: 1,
    };

    this.setGuageChart();
    // this.setTruthMeterLabel();

    // Here, need to use sanitizer and bypass security trust html
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(
      this.sampleHtmlContent
    );
    // After setting the content, add click listeners
    setTimeout(() => {
      this.addClickListenersToGuidElements();
    }, 0);

    this.tutorialService.tutorialHighlightType$.subscribe((highlightType) => {
      this.highlightedElement = parseInt(highlightType, 10);
      console.log("Element highlighted:")
      console.log(this.highlightedElement)

      if (this.highlightedElement === 9) {
        this.tutorialActive = true;
      
        const checkElementExistence = () => {
          const targetElement = document.querySelector('[data-guid="0a31beb1-f291-4ed1-945b-ee0fbdf53211"]'); // Update selector as per your DOM structure
          
          if (targetElement) {
            console.log("Found element");
            console.log(targetElement);
            
            const mouseEvent = new MouseEvent('mouseover', {
              bubbles: true,
              cancelable: true,
              clientX: 500,
              clientY: 350,
            });
            
            const x = 200;
            const y = 350;
            
            this.displayTutorialButtons(x, y);
            
            clearInterval(elementCheckInterval);
          } else {
            console.log('Target element not found, retrying...');
          }
        };
      
        const elementCheckInterval = setInterval(checkElementExistence, 500);
        setTimeout(() => clearInterval(elementCheckInterval), 10000);  // Stop retrying after 10 seconds
      } else {
        this.cleanupTutorialElements();
      }

      
      console.log("Init 2")
    });

    this.tutorialService.tutorialVisible$.subscribe((visible) => {
      this.tutorialActive = visible;
    });
  }


  cleanupTutorialElements() {
    const existingOverlay = document.getElementById('tutorial-overlay');
    if (existingOverlay) {
      existingOverlay.remove();
    }
  
    const existingButtonContainer = document.getElementById('tutorial-button-container');
    if (existingButtonContainer) {
      existingButtonContainer.remove();
    }
  
    this.tutorialActive = false; // You can reset any flags if needed
  }

  displayTutorialButtons(x: number, y: number) {
    // Remove any existing button container to avoid duplication
    const existingContainer = document.getElementById('tutorial-button-container');
    if (existingContainer) {
      existingContainer.remove();
    }
  
    // Create an overlay to darken the rest of the screen
    const overlay = this.renderer.createElement('div');
    this.renderer.setAttribute(overlay, 'id', 'tutorial-overlay');
    this.renderer.setStyle(overlay, 'position', 'fixed');
    this.renderer.setStyle(overlay, 'top', '0');
    this.renderer.setStyle(overlay, 'left', '0');
    this.renderer.setStyle(overlay, 'width', '100vw');
    this.renderer.setStyle(overlay, 'height', '100vh');
    this.renderer.setStyle(overlay, 'background', 'rgba(67, 67, 67, 0.2)');
    this.renderer.setStyle(overlay, 'z-index', '998'); // Lower than buttons and modal
    document.body.appendChild(overlay);
  
    const buttonContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(buttonContainer, 'id', 'tutorial-button-container');
    this.renderer.setStyle(buttonContainer, 'position', 'absolute');
    this.renderer.setStyle(buttonContainer, 'top', `${y}px`);
    this.renderer.setStyle(buttonContainer, 'left', `${x}px`);
    this.renderer.setStyle(buttonContainer, 'background', '#fff');
    this.renderer.setStyle(buttonContainer, 'box-shadow', '0 0 0 9999px rgba(67, 67, 67, 0.2)');
    this.renderer.setStyle(buttonContainer, 'border', '2px solid green');
    this.renderer.setStyle(buttonContainer, 'border-radius', '8px');
    this.renderer.setStyle(buttonContainer, 'padding', '10px');
    this.renderer.setStyle(buttonContainer, 'z-index', '999'); // Higher than the overlay
    this.renderer.setStyle(buttonContainer, 'display', 'flex');
    this.renderer.setStyle(buttonContainer, 'flex-direction', 'column'); // Stack buttons vertically
    this.renderer.setStyle(buttonContainer, 'gap', '0px'); // Reduced space between buttons
  
    const buttons = [
      { icon: '🔄', text: 'Re-run assertion' },
      { icon: '✏️', text: 'Edit assertion' },
      { icon: '📋', text: 'Copy to clipboard' },
      { icon: '📄', text: 'Check assertion' },
      { icon: '✏️', text: 'Edit error' }
    ];
  
    buttons.forEach(({ icon, text }) => {
      const button = this.renderer.createElement('button');
      const buttonText = this.renderer.createText(`${icon} ${text}`);
      this.renderer.appendChild(button, buttonText);
      this.renderer.setStyle(button, 'padding', '5px 10px');
      this.renderer.setStyle(button, 'border', '1px solid #ccc'); // Same as border style
      this.renderer.setStyle(button, 'border-radius', '4px');
      this.renderer.setStyle(button, 'background', '#f8f9fa');
      this.renderer.setStyle(button, 'cursor', 'pointer');
      this.renderer.setStyle(button, 'text-align', 'center');
      this.renderer.setStyle(button, 'margin', '1px');
      this.renderer.appendChild(buttonContainer, button);
    });

  const pointer = this.renderer.createElement('div');
  this.renderer.setStyle(pointer, 'position', 'absolute');
  this.renderer.setStyle(pointer, 'bottom', '0px');
  this.renderer.setStyle(pointer, 'left', '80px');
  this.renderer.setStyle(pointer, 'width', '30px');
  this.renderer.setStyle(pointer, 'height', '30px');
  this.renderer.setStyle(pointer, 'background', 'url("../../assets/images/svg/hand-cursor.svg") no-repeat center center');
  this.renderer.setStyle(pointer, 'background-size', 'contain');
  this.renderer.setStyle(pointer, 'z-index', '1000'); // Higher than the buttons
  this.renderer.appendChild(buttonContainer, pointer);
  
    // Append the container to the body
    document.body.appendChild(buttonContainer);
  }

  ngOnChanges(changes: any): void {
    if (
      changes?.truthMeter?.currentValue !==
        changes?.truthMeter?.previousValue &&
      changes?.truthMeter?.previousValue !== undefined
    ) {
      this.setGuageChart();
    }
  }

  addClickListenersToGuidElements() {
    // Select the container element using the ElementRef
    const contentSections = this.el.nativeElement.querySelectorAll(
      '.assertion-content-sec'
    );

    // Iterate through each 'assertion-content-sec' section
    contentSections.forEach((section: HTMLElement) => {
      // Find all elements with a data-guid attribute within this section
      const guidElements = section.querySelectorAll('[data-guid]');

      // Add a click event listener to each element
      guidElements.forEach((element: HTMLElement) => {
        const listener = this.renderer.listen(element, 'click', () => {
          const guid = element.getAttribute('data-guid');
          if (guid) {
            this.redirectToAssertion(guid);
          }
        });

        this.eventListeners.push(listener);
      });
    });
  }

  ngAfterViewInit() {
    
    // Below code implements bob effect.
    this.addClickListenersToGuidElements();
    setTimeout(() => {
      this.evaluateSyllogisms();
      let step = 1;
      this.options.needleUpdateSpeed = 800;
      const outerInterval = setInterval(() => {
        if (step === 1) {
          this.needleValue = (parseInt(this.needleValue, 10) + 6).toString();
        } else if (step === 2) {
          this.needleValue = (parseInt(this.needleValue, 10) - 6).toString();
        } else if (step === 3) {
          this.needleValue = (parseInt(this.needleValue, 10) - 5).toString();
        } else if (step === 4) {
          this.needleValue = (parseInt(this.needleValue, 10) + 7).toString();
        } else if (step === 5) {
          this.needleValue = (parseInt(this.needleValue, 10) - 4).toString();
        } else if (step === 6) {
          this.needleValue = (parseInt(this.needleValue, 10) + 2).toString();
        }
        step++;
        if (step > 6) {
          step = 1;
        }
      }, 800);
      // this.addStaticTextToParagraphs();
      // this.addScoresToParagraphs();

    }, 5000);

    // setTimeout(() => {
    //   this.addStaticTextToParagraphs();
    // }, 100); 
    this.cdr.detectChanges(); // Trigger change detection

      }


      updateSyllogismsBackgroundClass(): void {
        if (this.percentageProcessed < 25) {
          this.syllogismsBackgroundClass = 'syllogisms-bg-0';
        } else if (this.percentageProcessed >= 25 && this.percentageProcessed < 50) {
          this.syllogismsBackgroundClass = 'syllogisms-bg-25';
        } else if (this.percentageProcessed >= 50 && this.percentageProcessed < 75) {
          this.syllogismsBackgroundClass = 'syllogisms-bg-50';
        } else if (this.percentageProcessed >= 75 && this.percentageProcessed < 100) {
          this.syllogismsBackgroundClass = 'syllogisms-bg-75';
        } else if (this.percentageProcessed === 100) {
          this.syllogismsBackgroundClass = 'syllogisms-bg-100';
        }
        this.cdr.detectChanges();
      }


      evaluateSyllogisms() {
        const docId = this.docId; // Use docId to uniquely identify the document
        const localStorageKey = `syllogisms_${docId}`;
      
        // ✅ Check if syllogisms for this document are already in localStorage
        const cachedSyllogisms = localStorage.getItem(localStorageKey);
        if (cachedSyllogisms) {
          console.log("Using cached syllogisms from localStorage.");
          this.syllogims = JSON.parse(cachedSyllogisms);
          this.processSyllogismsResult(this.syllogims);
          this.percentageProcessed = 100;
          return;
        }
      
        // ⏳ Proceed to evaluate syllogisms if not found in localStorage
        console.log("Evaluating syllogisms...");
      
        const payloadSentences = this.sentenceData.map(item => item.sentence);
        let userId = localStorage.getItem('userId') || "";
        let token = localStorage.getItem('access_token') || "";
      
        let nonEmptySyllogismsCount = 0;
        const chunkSize = 2;
        const totalSentences = payloadSentences.length;
        let currentIndex = 0;
        const allSyllogisms: any[] = [];
      
        const evaluateSyllogismsInChunks = () => {
          const chunk = payloadSentences.slice(currentIndex, currentIndex + chunkSize);
      
          this.userService.getsyllogimsData(userId, token, chunk).subscribe(
            (res: any) => {
              // ✅ Process syllogisms
              const currentChunkCount = res.answersPerParagraph.filter((arr: any[]) => arr.length > 0).length;
              nonEmptySyllogismsCount += currentChunkCount;
              allSyllogisms.push(...res.answersPerParagraph);
              this.cdr.detectChanges();
      
              processNextChunk();
            },
            (error) => {
              // ❌ Handle API error
              console.error("API Error:", error);
              allSyllogisms.push(...chunk.map(() => [])); // Treat as empty syllogisms
      
              // Continue processing
              this.openToaster(false, true, `Error processing some sentences. Continuing...`, 3000);
              this.cdr.detectChanges();
              processNextChunk();
            }
          );
        };
      
        const processNextChunk = () => {
          const percentageProcessed = ((currentIndex + chunkSize) / totalSentences) * 100;
          const percentageText = Math.min(Math.floor(percentageProcessed), 100);

          this.updateSyllogismsBackgroundClass();
      
          this.openToaster(
            true,
            false,
            `Document processed: ${percentageText}%. Syllogisms found: ${nonEmptySyllogismsCount}`,
            percentageText === 100 ? 0 : 5000
          );
      
          this.percentageProcessed = percentageText;
          this.foundSyllogismsCount = nonEmptySyllogismsCount;
      
          currentIndex += chunkSize;
      
          if (currentIndex < totalSentences) {
            evaluateSyllogismsInChunks();
          } else {
            // ✅ All sentences processed, use the combined result
            this.syllogims.push(...allSyllogisms);
      
            // ✅ Store the final syllogisms result in localStorage
            localStorage.setItem(localStorageKey, JSON.stringify(this.syllogims));
            console.log("Syllogisms cached in localStorage:", this.syllogims);
      
            this.processSyllogismsResult(this.syllogims);
          }
        };
      
        // Start evaluating syllogisms
        evaluateSyllogismsInChunks();
      }

      private processSyllogismsResult(syllogisms: any[]): void {
        this.syllogims = syllogisms;
        this.foundSyllogismsCount = syllogisms.filter(arr => arr.length > 0).length;
        console.log("Processed syllogisms:", this.syllogims);
      }

      

    // Function to add static text before paragraphs (using the same method as for buttons)
    addStaticTextToParagraphs() {
      console.log('Adding static text before paragraphs...');
      const content = this.el.nativeElement.querySelector('.assertion-content-sec');
      
      if (content) {
        console.log(content);
        const paragraphs = content.querySelectorAll('p');
        console.log(`Found ${paragraphs.length} paragraphs.`);
        
        paragraphs.forEach((paragraph: HTMLElement, index: number) => {
          console.log(`Adding static text before Paragraph ${index + 1}`);
          
          // Create the static text element
          const debugText = this.renderer.createElement('span');
          this.renderer.setProperty(debugText, 'textContent', `Static Text Before Paragraph ${index + 1} - `);
          
          // Check if the paragraph has children (like <span>) and insert before the first child
          if (paragraph.firstChild) {
            this.renderer.insertBefore(paragraph, debugText, paragraph.firstChild);
          } else {
            // If no children, just append the static text directly to the paragraph
            this.renderer.appendChild(paragraph, debugText);
          }
        });
      } else {
        console.log('No content section found!');
      }
    }

    addScoresToParagraphs() {
      console.log('Adding scores to paragraphs...');
      const content = this.el.nativeElement.querySelector('.assertion-content-sec');
    
      if (content) {
        const paragraphs = content.querySelectorAll('p');
    
        paragraphs.forEach((paragraph: HTMLElement) => {
          // Remove any existing text-indent
          paragraph.style.textIndent = '0';
    
          const sentences = paragraph.querySelectorAll('.truth-value-text');
          let totalScore = 0;
          let sentenceCount = 0;
    
          sentences.forEach((sentence: HTMLElement) => {
            // Extract truth value from the data attribute
            const truthValue = parseInt(sentence.getAttribute('data-truth-value') || '0');
            if (truthValue !== 50){
            totalScore += truthValue;
            sentenceCount++;
          }
          });
    
          if (sentenceCount > 0) {
            // Calculate the average score
            let paragraphScore = totalScore / sentenceCount;
    
            // Create a span to display the score
            const scoreText = this.renderer.createElement('span');
            this.renderer.addClass(scoreText, 'paragraph-score');
            this.renderer.setProperty(scoreText, 'textContent', `${paragraphScore.toFixed(0)}`);
    
            // Apply styles to make it a circle
            this.renderer.setStyle(scoreText, 'border-radius', '50%');

            // Check for paragraphScore and update it accordingly
            this.renderer.setProperty(scoreText, 'textContent', `${paragraphScore.toFixed(0)}`);


              if (paragraphScore == -1) {
                this.renderer.setStyle(scoreText, 'border', '2px solid #717171'); // Grey
              } else if (paragraphScore < 33) {
                this.renderer.setStyle(scoreText, 'border', '2px solid #FF1919'); // Red
              } else if (paragraphScore < 66) {
                this.renderer.setStyle(scoreText, 'border', '2px solid #FFCC00'); // Yellow
              } else {
                this.renderer.setStyle(scoreText, 'border', '2px solid #008400'); // Green
              }

            // this.renderer.setStyle(scoreText, 'border', '2px solid #008400');
            this.renderer.setStyle(scoreText, 'width', '24px');
            this.renderer.setStyle(scoreText, 'height', '24px');
            // this.renderer.setStyle(scoreText, 'display', 'inline-block');
            this.renderer.setStyle(scoreText, 'text-align', 'center');
            this.renderer.setStyle(scoreText, 'margin-right', '10px');
            this.renderer.setStyle(scoreText, 'padding', '2px');
            this.renderer.setStyle(scoreText, 'vertical-align', 'top');

            // Insert the score span as the first child of the paragraph
            paragraph.insertAdjacentElement('afterbegin', scoreText);
    
            // Apply padding and text-indent to align remaining spans
            paragraph.style.paddingLeft = '34px';  // Adjust based on the score width
            paragraph.style.textIndent = '-34px';  // Match padding to ensure correct alignment
          }
        });
      } else {
        console.log('No content section found!');
      }
    }


  setGuageChart() {
    this.pvgprogress = this.truthMeter;
    const pvyProgressValue = 100 - Number(this.truthMeter);
    this.pvyprogress = pvyProgressValue.toFixed(1).replace(/\.0$/, '');

    this.needleValue = `${this.truthMeter}`;
    if (this.truthMeter >= 0 && this.truthMeter <= 20) {
      this.graphType = 'Very Unlikely';
    } else if (this.truthMeter >= 21 && this.truthMeter <= 40) {
      this.graphType = 'Unlikely';
    } else if (this.truthMeter >= 41 && this.truthMeter <= 60) {
      this.graphType = 'Partially True';
    } else if (this.truthMeter >= 61 && this.truthMeter <= 80) {
      this.graphType = 'Likely';
    } else {
      this.graphType = 'Very Likely';
    }
  }

  setTruthMeterLabel() {
    switch (this.labelVal) {
      case 0:
        this.labelText = 'Google Answer';
        break;
      case 1:
        this.labelText = 'Rank System 1';
        break;
      case 2:
        this.labelText = 'News System Counter';
        break;
      case 3:
        this.labelText = 'Human';
        break;
      case 4:
        this.labelText = 'Google Answer With ChatGPT';
        break;
      case 5:
        this.labelText = 'ChatGPT Answer & Result';
        break;
      default:
        this.labelText = 'Unknown';
    }
  }

  showCorrection() {
    this.showcorrectionTooltip = true;
  }
  closeCorrection() {
    this.showcorrectionTooltip = false;
    console.log('closed');
  }
  toSentenceCase(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  openModal(option: any) {
    if (option == 'Fallacies') {
      console.log('total logical fallacies ::', this.sentenceData);
      sessionStorage.removeItem('fallacies');
      let fallacies = [];
      for (let sentenceObj of this.sentenceData) {
        if (
          sentenceObj &&
          sentenceObj.fallacyDetails &&
          sentenceObj.fallacyDetails?.length > 0
        ) {
          let temp = {
            assertion: sentenceObj.sentence,
            fallacy_score: sentenceObj.fallacyDetails[0].fallacyScore,
            fallacy_name: this.toSentenceCase(
              sentenceObj.fallacyDetails[0].fallacyName
            ),
          };
          fallacies.push(temp);
        }
      }
      sessionStorage.setItem('fallacies', JSON.stringify(fallacies));

      this.modalPopupService.setPopup(option);
      sessionStorage.setItem('option', option);
      setTimeout(() => {
        this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
          panelClass: ['global-modal-popup', 'animate__animated', 'zoomIn'],
        });

        this.dialogRef.disableClose = true;
      }, 200);
    }
    if (option == 'Syllogims') {
      sessionStorage.removeItem('syllogims');
      sessionStorage.setItem('syllogims', JSON.stringify(this.syllogims));

      if (this.syllogims.length == 0) {
        this.openToaster(
          true,
          false,
          'Identifying syllogisms... It may take a few seconds.'
        );
      } else {
        this.modalPopupService.setPopup(option);
        sessionStorage.setItem('option', option);
        setTimeout(() => {
          this.dialogRef = this.dialog.open(GlobalmodalpopupComponent, {
            panelClass: ['global-modal-popup', 'animate__animated', 'zoomIn'],
          });

          this.dialogRef.disableClose = true;
        }, 200);
      }
    }
  }

  getSyllogims() {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');

    this.syllogims = [];
    let syllogimsPara = '';
    for (let sentence of this.sentenceData) {
      syllogimsPara += encodeURIComponent(sentence.sentence) + ' ';
    }

    // this.userService
    //   .getsyllogimsData(userId, token, syllogimsPara)
    //   .subscribe((res: any) => {
    //     this.syllogims.push(res);
    //   });
  }

  redirectToAssertion(guid: any) {
    // console.log(this.docId);
    sessionStorage.removeItem('all_assertions');
    let resultData = this.sentenceData.filter((data: any) => {
      return data.guid == guid;
    });
    if (resultData.length === 0) {
      return;
    } else {
      resultData[0].results.push({ query: resultData[0].sentence });
      console.log(resultData);
    }
    console.log(resultData);
    console.log(resultData[0].results[0].query);

    sessionStorage.setItem(
      'all_assertions',
      JSON.stringify(this.allAssertions)
    );

    if (
      resultData.length > 0 &&
      resultData[0].results &&
      resultData[0].results[0].query
    ) {

      console.log(this.allAssertions);
      console.log('guid', guid);
      console.log(this.sentenceData);
      const foundObject = this.sentenceData.find(item => item.guid === guid);
      localStorage.setItem('selectedAssertion', JSON.stringify(foundObject));

      console.log("Clicked on assertion: ")
      this.removeEditButtons();
      this.router.navigate(['/query'], {
        queryParams: { search: resultData[0].results[0].query },
      });
    }
  }

  downloadDocument() {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    this.userService.downloadDocumentsById(userId, token, this.docId).subscribe(
      (Response: any) => {
        console.log(Response);
        if (Response.data && Response.meta.code == 200) {
          window.open(Response.data, '_blank');
          this.openToaster(true, false, 'File downloaded successfully.');
        } else {
          this.openToaster(
            false,
            true,
            'Failed to retrieve user document File.'
          );
        }
      },
      (error: any) => {
        this.openToaster(false, true, 'Failed to retrieve user document data.');
        console.log(error);
      }
    );
  }

  readDocumentById(docId: string) {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    this.userService.getUserDocumentsById(userId, token, docId).subscribe(
      (Response: any) => {
        if (Response.data && Response.meta.code == 200) {
          this.documentData = Response.data;

          const htmlFileUrl = this.documentData.htmlFileUrl;
          console.log("Storing document score: ", this.documentScore)
          console.log(Response.data)
          this.documentScore = Response.data.documentScore;

          this.loadHtmlContentFromUrl(htmlFileUrl);
        } else if (Response.meta.code == 1006) {
          console.log('No Document Found');
          this.openToaster(false, true, 'No Document Found');
          this.documentData = {};
        } else {
          this.documentData = {};
          this.openToaster(false, true, 'Failed to fetch document data.');
        }
      },
      (error: any) => {
        this.openToaster(false, true, 'Failed to fetch document data.');
        console.log(error);
      }
    );
  }

  getSentenceById(docId: string) {
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    
    this.userService.getSentencesById(userId, token, docId).subscribe(
      (Response: any) => {
        if (Response.meta.sentences.length > 0) {
          this.sentenceData = Response.meta.sentences;

          // localStorage.setItem("errorSearchData", this.sentenceData);
          sessionStorage.setItem('errorSearchData', JSON.stringify(this.sentenceData));
  
          this.corrections = 0;
          let validTruthCount = 0;
          let sumOfTruth = 0;
          this.allAssertions = [];
          for (let sentence of this.sentenceData) {
            if (sentence.truthValue == -1) {
              this.corrections++;
            } else {
              validTruthCount++;
              sumOfTruth += sentence.truthValue;
            }
            this.allAssertions.push(sentence.results.query);
          }
          this.truthMeter = sumOfTruth / validTruthCount;
  
          let planType = localStorage.getItem('plan_name');
  
          if (planType == "Legit™ Premium") {
            this.truthMeter = this.documentScore;
            
          }
  
          this.setGuageChart();
          this.getSyllogims();
        } else if (Response.meta.code == 1006) {
          console.log('No Sentences Found');
          this.sentenceData = {};
        } else {
          this.sentenceData = {};
        }
      },
      (error: any) => {
        this.openToaster(false, true, 'Failed to retrieve user Sentences data.');
        console.log(error);
      }
    );
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string, timeout: number = 5000) {
    this.closeToaster();
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
      if (timeout === 5000){
      setTimeout(() => {
        this.closeToaster();
      }, timeout);
    }
    } else if (isError) {
        this.showErrorToaster = true;
        this.showErrorMsg = message;

    }
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }

  // Method to determine the class based on truthvalue
  getClassForTruthValue(truthValue: number): string {
    if (truthValue === -1) {
      return 'correction';
    } else if (truthValue >= 0 && truthValue < 34) {
      return 'false';
    } else if (truthValue >= 34 && truthValue < 78) {
      return 'partially';
    } else if (truthValue >= 78) {
      return 'true';
    } else {
      return '';
    }
  }

  loadHtmlContentFromUrl(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((html: string) => {
      // Update the HTML content where `data-error-name="Question?"` is found
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      
      // Select all elements with `data-error-name="Question?"`
      const elements = doc.querySelectorAll('[data-error-name="Question?"]');
      elements.forEach((element) => {
        element.setAttribute('style', 'color: #191919;');
        element.setAttribute('data-truth-value', '50');
      });
  
      // Serialize the updated HTML content
      const updatedHtml = doc.documentElement.outerHTML;
  
      // Assign the updated HTML to your variable
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(updatedHtml);
  
      console.log('Updated HTML content:');
      console.log(this.htmlContent);

      // After setting the content, add click listeners
      setTimeout(() => {
        this.addClickListenersToGuidElements();
        this.addScoresToParagraphs();
      }, 0);
    });
  }

  ngOnDestroy() {
    // Remove all event listeners when the component is destroyed
    this.eventListeners.forEach((unregister) => unregister());
    this.eventListeners = []; // Clear the array after removal
  }

  // Mouseover handler
  onMouseOver(event: MouseEvent) {
    if (this.tutorialActive) {
      return;
    }
    const target = event.target as HTMLElement;

    if (target.hasAttribute('data-guid')) {
      setTimeout(() => {
        this.showEditButton(event, target);
      }, 0);
    }
  }

  // Function to show the edit and rerun buttons
  showEditButton(event: MouseEvent, target: HTMLElement) {
    // Remove any existing buttons to avoid conflicts
    this.removeEditButtons();

    const truthValue = target.getAttribute('data-truth-value');
    const isCorrectionNeeded = truthValue === '-1';
    const buttonLabel = isCorrectionNeeded ? 'Edit error' : 'Edit assertion';
    const buttonIcon = '✏️';

    // Create the "Assertion Results Page" button
    const resultsButton = this.createButton(
      '📄',
      ' Assertion Results Page',
      event.pageY - 10,
      event.pageX
    );
    this.renderer.listen(resultsButton, 'click', () => {
      const searchText = target.textContent?.trim();
      this.removeEditButtons();
      this.router.navigate(['/query'], { queryParams: { search: searchText } });
    });
    document.body.appendChild(resultsButton);

    // Create the "Edit assertion / Check assertion" button
    const editButton = this.createButton(buttonIcon, `Edit assertion`, event.pageY - 42, event.pageX);

    const editErrorButton = this.createButton(buttonIcon, `Edit error`, event.pageY - 138, event.pageX);
    // Attach the appropriate event listener based on the button's purpose
    if (isCorrectionNeeded) {
      this.renderer.listen(editErrorButton, 'click', () => {
        this.removeEditButtons();
        const foundObject = this.sentenceData.find(item => item.guid === target.getAttribute("data-guid"));
        localStorage.setItem('selectedAssertion', JSON.stringify(foundObject));
        this.router.navigate(['/query'], { queryParams: { search: foundObject?.sentence || '' } });
      });
      document.body.appendChild(editErrorButton);
    } 
    // else {
        this.renderer.listen(editButton, 'click', () => this.editSentence(target));
    // }

    // Append the button to the DOM
    document.body.appendChild(editButton);

    // Create the "Copy to Clipboard" button
    const copyButton = this.createButton(
      '📋',
      ' Copy to clipboard',
      event.pageY - 74,
      event.pageX
    );
    this.renderer.listen(copyButton, 'click', () =>
      this.copyToClipboard(target)
    );
    document.body.appendChild(copyButton);

    // Create the "Re-run Assertions" button
    const rerunButton = this.createButton(
      '🔄',
      ' Re-run assertion',
      event.pageY - 106,
      event.pageX
    );
    this.renderer.listen(rerunButton, 'click', () =>
      this.rerunAssertion(target)
    );
    document.body.appendChild(rerunButton);

    // Conditionally create the "Re-run all Assertions" button
    let rerunAllButton: HTMLElement | null = null;
    if (this.assertionsArray && this.assertionsArray.length > 0) {
      rerunAllButton = this.createButton(
        '🔁',
        ' Re-run saved assertions',
        event.pageY - 138,
        event.pageX
      );
      this.renderer.listen(rerunAllButton, 'click', () =>
        this.rerunAllAssertions(target)
      );
      document.body.appendChild(rerunAllButton);
    }

    // Attach a listener to clean up the buttons when the mouse leaves the sentence
    const mouseLeaveListener = (e: MouseEvent) => {
      if (this.tutorialActive) {
        return;
      }
      const hoveredElement = e.target as Node;

      // Check the presence of rerunAllButton before referencing it
      const isHoveredOutside =
        !target.contains(hoveredElement) &&
        !resultsButton.contains(hoveredElement) &&
        !editButton.contains(hoveredElement) &&
        !editErrorButton.contains(hoveredElement) &&
        !copyButton.contains(hoveredElement) &&
        !rerunButton.contains(hoveredElement) &&
        (!rerunAllButton || !rerunAllButton.contains(hoveredElement));

      if (isHoveredOutside) {
        this.removeEditButtons();
        document.removeEventListener('mousemove', mouseLeaveListener); // Cleanup listener
      }
    };

    document.addEventListener('mousemove', mouseLeaveListener);
  }

  // Function to copy text to clipboard
  copyToClipboard(target: HTMLElement) {
    const text = target.innerText; // Get the text content of the target element
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Text copied to clipboard:', text);
        this.openToaster(true, false, 'Text copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy text to clipboard:', err);
        this.openToaster(false, true, 'Failed to copy text to clipboard!');
      }
    );
  }

  // Utility function to create a button
  createButton(
    icon: string,
    text: string,
    top: number,
    left: number
  ): HTMLButtonElement {
    const button = this.renderer.createElement('button');
    this.renderer.addClass(button, 'custom-button'); // Shared style class

    // Set position
    this.renderer.setStyle(button, 'top', `${top}px`);
    this.renderer.setStyle(button, 'left', `${left}px`);

    // Add icon
    const iconElement = this.renderer.createElement('span');
    this.renderer.setProperty(iconElement, 'innerHTML', icon);

    // Add text
    const textElement = this.renderer.createElement('span');
    this.renderer.setProperty(textElement, 'innerHTML', text);

    // Append icon and text to button
    this.renderer.appendChild(button, iconElement);
    this.renderer.appendChild(button, textElement);

    return button;
  }

  // Function to remove all existing buttons
  removeEditButtons() {
    const existingButtons = document.querySelectorAll('.custom-button');
    existingButtons.forEach((button) => button.remove());
  }

  // Function to handle the sentence edit action
  editSentence(target: HTMLElement) {
    this.editingSentence = true;
    this.removeEditButtons();    
    console.log("Target: ", target);
    this.editedText = target.innerText;
    this.currentGuid = target.getAttribute('data-guid') || '';
  }

  // Function to handle re-running assertions
  rerunAssertion(target: HTMLElement) {
    this.removeEditButtons();
    const guid = target.getAttribute('data-guid');
    this.isLoading = true;
    // const sentenceText = target.innerText;
    const sentenceText =
      this.assertionsArray.find((a) => a.guid === guid)?.updatedAssertionText ||
      target.innerText;
    let userId = localStorage.getItem('userId');
    console.log('Re-running assertion. GUID:', guid, 'Text:', sentenceText);
    this.userService
      .rerunAssertion(guid, sentenceText, this.docId, userId)
      .subscribe(
        (res: any) => {
          console.log('Saved successfully:', res);
          // Reinitialize methods to refresh data
          this.readDocumentById(this.docId);
          this.getSentenceById(this.docId);
          this.isLoading = false;

          this.assertionsArray = this.assertionsArray.filter(
            (a) => a.guid !== guid
          );
          localStorage.setItem(
            'assertions',
            JSON.stringify(this.assertionsArray)
          ); // Save updated array to localStorage
          setTimeout(() => {
            this.updateHtmlContent();
          }, 500);

          this.openToaster(true, false, 'Executed successfully!');
        },
        (err) => {
          console.error('Error saving data:', err);
        }
      );
  }

  // Function to handle re-running all assertions
  rerunAllAssertions(target: HTMLElement) {
    this.removeEditButtons();
    let userId = localStorage.getItem('userId');
    console.log('Re-running all assertions.');
    if (this.assertionsArray.length > 0) {
      this.isLoading = true;

      this.userService
        .rerunAllAssertions(this.assertionsArray, this.docId, userId)
        .subscribe(
          (res: any) => {
            console.log('Saved successfully:', res);
            // Reinitialize methods to refresh data
            this.readDocumentById(this.docId);
            this.getSentenceById(this.docId);
            this.isLoading = false;

            this.assertionsArray = [];
            localStorage.setItem(
              'assertions',
              JSON.stringify(this.assertionsArray)
            ); // Save updated array to localStorage
            setTimeout(() => {
              this.updateHtmlContent();
            }, 500);

            this.openToaster(true, false, 'Executed successfully!');
          },
          (err) => {
            console.error('Error saving data:', err);
          }
        );
    } else {
      this.openToaster(true, false, 'New changes not found in assertion(s)');
    }
  }

  save() {
    console.log('Saving assertion locally. GUID:', this.currentGuid);

    // Check if an assertion with the same GUID exists
    const existingAssertion = this.assertionsArray.find(
      (assertion) => assertion.guid === this.currentGuid
    );

    if (existingAssertion) {
      // Update the text if GUID already exists
      existingAssertion.updatedAssertionText = this.editedText;
      console.log('Updated assertion:', existingAssertion);
    } else {
      // Add a new assertion if GUID doesn't exist
      this.assertionsArray.push({
        guid: this.currentGuid,
        updatedAssertionText: this.editedText,
      });
      this.openToaster(true, false, 'Updated assertion successfully!');
      console.log('Added new assertion:', this.assertionsArray);
    }

    localStorage.setItem('assertions', JSON.stringify(this.assertionsArray));

    this.updateHtmlContent();

    this.cancelEdit();
  }

  updateHtmlContent() {
    console.log('In update html');
    // if (this.currentGuid && this.editedText) {
    //   // Search the HTML content for an element with the data-guid matching currentGuid
    //   const element = document.querySelector(`[data-guid="${this.currentGuid}"]`);

    //   if (element) {
    //     // Cast the element to HTMLElement to access innerText
    //     (element as HTMLElement).innerText = this.editedText;
    //     (element as HTMLElement).style.color = '#808080';
    //   }
    // }
    if (this.assertionsArray && this.assertionsArray.length > 0) {
      console.log('Assertions array:', this.assertionsArray);

      // Ensure we have time for DOM elements to be available
      setTimeout(() => {
        this.assertionsArray.forEach((assertion) => {
          const element = document.querySelector(
            `[data-guid="${assertion.guid}"]`
          );

          if (element) {
            console.log('Found element for GUID:', assertion.guid);
            console.log(element);

            const htmlElement = element as HTMLElement;

            // Update the text and style
            htmlElement.innerText = assertion.updatedAssertionText;
            htmlElement.style.color = '#808080';
            htmlElement.style.backgroundColor = '#fcff96';
          } else {
            console.log('Element not found for GUID:', assertion.guid);
          }
        });
        this.addScoresToParagraphs();

      }, 1000); // 100ms delay to give DOM time
    }
  }

  // Save and cancel edit
  saveAndReRun() {
    console.log('Edited GUID:', this.currentGuid);
    console.log('Edited Text:', this.editedText);
    let userId = localStorage.getItem('userId');
    this.isLoading = true;

    this.userService
      .saveAssertion(this.currentGuid, this.editedText, this.docId, userId)
      .subscribe(
        (res: any) => {
          console.log('Saved successfully:', res);
          // Reinitialize methods to refresh data
          this.readDocumentById(this.docId);
          this.getSentenceById(this.docId);
          this.isLoading = false;
          this.openToaster(true, false, 'Executed successfully!');
        },
        (err) => {
          console.error('Error saving data:', err);
        }
      );

    this.cancelEdit();
  }

  cancelEdit() {
    this.editingSentence = false;
    this.editedText = '';
    this.currentGuid = '';
    this.removeEditButtons();
  }
}

// form = new FormGroup({
//   editorContent: new FormControl(
//      { value: jsonDoc, disabled: false },
//     Validators.required()
//   ),
// });
