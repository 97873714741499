import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

export interface TutorialStep {
  content: string;
  title: string;
  route?: string;
  highlightType: string;
}

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  private tutorialContentSubject = new BehaviorSubject<string>('');
  private tutorialTitleSubject = new BehaviorSubject<string>('');
  private tutorialHighlightTypeSubject = new BehaviorSubject<string>('');
  private tutorialVisibleSubject = new BehaviorSubject<boolean>(false);

  private steps: TutorialStep[] = [
    { route: '/', content: 'Discover how Legit™ Premium can analyze your documents with advanced tools like validity scoring, logical fallacy detection, and comprehensive results. Let’s get started!', title: 'Welcome to Legit™ Premium!', highlightType: "99" },
    { route: '/', content: 'Use this box to type or paste sentences or paragraphs. Once entered, the text will be processed and saved in your history for easy access.', title: 'Type or Paste Assertions Here', highlightType: "1" },
    { route: '/', content: 'Click here to upload supported files for analysis. Accepted formats include .doc, .docx, .txt, .mp4, and .mp3. Uploaded files will be saved in the history section for processing.', title: 'Upload Files for Analysis', highlightType: "2" },
    { route: '/', content: 'Find all your processed and uploaded files here. Once a file is processed, it will turn green. Click a green file to view its results.', title: 'View Your Files in History', highlightType: "3" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: 'Your document is analyzed sentence by sentence. Green indicates true, yellow indicates partially true, red indicates false, and grey indicates an errored sentence (more on that later). Hover over any sentence for more details.', title: 'Understanding Validity Colors', highlightType: "3" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: 'The overall score is a composite of the document’s validity. Use this score to gauge the document’s accuracy and reliability at a glance.', title: 'Check the Overall Score', highlightType: "4" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: 'View detailed document stats here, including total sentences, character count, assertions with errors, and logical fallacies detected.', title: 'Document Statistics', highlightType: "5" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: 'Click this button to view syllogisms found in the document. Syllogisms are key to logical reasoning and argument structure.', title: 'Analyze Syllogisms', highlightType: "6" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: 'Click here to reveal logical fallacies in your document. Understanding fallacies is crucial for improving argument quality.', title: 'Detect Logical Fallacies', highlightType: "7" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: 'Grey sentences indicate errors such as stop words, uncivil words, or spelling and grammar issues. Click on a grey sentence to see which error system is causing the error detection and fix it.', title: 'Fix Greyed Out Sentences', highlightType: "8" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: 'Hover over any sentence to access the assertion options menu. Copy, edit, or re-run assertions for further analysis. You can also click on Check Assertion for detailed results for that specific assertion.', title: 'Manage Individual Sentences', highlightType: "9" },

    { route: '/search-results-view?assertion=67868ef392e6130ca2ed3928', content: 'Your document is analyzed sentence by sentence. Green indicates true, yellow indicates partially true, red indicates false, and grey indicates an errored sentence (more on that later). Hover over any sentence for more details.', title: 'Understanding Validity Colors', highlightType: "3" },
    { route: '/search-results-view?assertion=67868ef392e6130ca2ed3928', content: 'The overall score is a composite of the document’s validity. Use this score to gauge the document’s accuracy and reliability at a glance.', title: 'Check the Overall Score', highlightType: "4" },
    { route: '/search-results-view?assertion=67868ef392e6130ca2ed3928', content: 'View detailed document stats here, including total sentences, character count, assertions with errors, and logical fallacies detected.', title: 'Document Statistics', highlightType: "5" },
    { route: '/search-results-view?assertion=67868ef392e6130ca2ed3928', content: 'Click this button to view syllogisms found in the document. Syllogisms are key to logical reasoning and argument structure.', title: 'Analyze Syllogisms', highlightType: "6" },
    { route: '/search-results-view?assertion=67868ef392e6130ca2ed3928', content: 'Click here to reveal logical fallacies in your document. Understanding fallacies is crucial for improving argument quality.', title: 'Detect Logical Fallacies', highlightType: "7" },
    { route: '/search-results-view?assertion=67868ef392e6130ca2ed3928', content: 'Grey sentences indicate errors such as stop words, uncivil words, or spelling and grammar issues. Click on a grey sentence to see which error system is causing the error detection and fix it.', title: 'Fix Greyed Out Sentences', highlightType: "8" },
    { route: '/search-results-view?assertion=67868ef392e6130ca2ed3928', content: 'Hover over any sentence to access the assertion options menu. Copy, edit, or re-run assertions for further analysis. You can also click on Check Assertion for detailed results for that specific assertion.', title: 'Manage Individual Sentences', highlightType: "9" },



    // { route: '/search=This%20explains%20why%20even%20today,%20scientists%20cannot%20replicate%20the%20construction%20techniques%20or%20understand%20the%20exact%20methods%20used%20to%20build%20these%20massive%20monuments.', content: 'Click any valid sentence to view its detailed analysis. See a comprehensive answer, truth meter score, supporting URLs, and options to create citations.', title: 'Detailed Assertion Analysis', highlightType: "99" },
    // { route: '/search=This%20explains%20why%20even%20today,%20scientists%20cannot%20replicate%20the%20construction%20techniques%20or%20understand%20the%20exact%20methods%20used%20to%20build%20these%20massive%20monuments.', content: 'You’ve completed the tutorial! Start analyzing your own documents with Legit™ Premium™ and unlock the full power of assertion analysis.', title: 'You’re Ready to Begin!', highlightType: "99" },

    { route: '/search=Critical%20thinking%20has%20become%20a%20cornerstone%20of%20modern%20education,%20fostering%20analytical%20skills%20and%20informed%20decision-making%20among%20students.', content: 'Click any valid sentence to view its detailed analysis. See a comprehensive answer, truth meter score, supporting URLs, and options to create citations.', title: 'Detailed Assertion Analysis', highlightType: "99" },
    { route: '/search=Critical%20thinking%20has%20become%20a%20cornerstone%20of%20modern%20education,%20fostering%20analytical%20skills%20and%20informed%20decision-making%20among%20students.', content: 'You’ve completed the tutorial! Start analyzing your own documents with Legit™ Premium™ and unlock the full power of assertion analysis.', title: 'You’re Ready to Begin!', highlightType: "99" },


    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: '', title: '', highlightType: "9" },
    // { route: '/search-results-view?assertion=676099b21b26ce225dbabb37', content: '', title: '', highlightType: "9" },

  ];


  

  


  tutorialContent$: Observable<string> = this.tutorialContentSubject.asObservable();
  tutorialTitle$: Observable<string> = this.tutorialTitleSubject.asObservable();
  tutorialVisible$: Observable<boolean> = this.tutorialVisibleSubject.asObservable();
  tutorialHighlightType$: Observable<string> = this.tutorialHighlightTypeSubject.asObservable();

  

  private boxShadowFullSubject = new BehaviorSubject<boolean>(false);
  boxShadowFull$ = this.boxShadowFullSubject.asObservable();

  private currentStepIndex: number = 0;

  private modalSubject = new BehaviorSubject<boolean>(false); // modal visibility
  modal$ = this.modalSubject.asObservable(); // Expose modal visibility as observable

  private highlightedElementSubject = new BehaviorSubject<number>(0); // Track highlighted element
  highlightedElement$ = this.highlightedElementSubject.asObservable(); // Expose as observable

  constructor(private router: Router) {  }

  getStepsCount(): number {
    return this.steps.length;
  }

  setTutorialSteps(steps: { stepIndex: number; title: string; content: string; route?: string; highlightType: string }[]) {
    this.steps = steps;
  }

  // Methods to update the tutorial content
  startTutorial() {
    this.currentStepIndex = 0;
    this.tutorialVisibleSubject.next(true);
    this.updateTutorialStep();
    this.navigateToStep(this.currentStepIndex); // Navigate to the first step route
  }

  nextStep(): TutorialStep | void {
    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++;
      this.updateTutorialStep();
      this.navigateToStep(this.currentStepIndex); // Navigate to the next step route
      return this.steps[this.currentStepIndex];
    }
    return;
  }

  previousStep(): TutorialStep | void {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.updateTutorialStep();
      this.navigateToStep(this.currentStepIndex); // Navigate to the previous step route
      return this.steps[this.currentStepIndex];
    }
    return undefined; 
  }

  endTutorial() {
    this.tutorialVisibleSubject.next(false);
    this.tutorialContentSubject.next('');
    this.tutorialTitleSubject.next('');
    this.tutorialHighlightTypeSubject.next('');
    this.router.navigate(['/']); // Optionally redirect when tutorial ends
  }

  private updateTutorialStep() {
    const step = this.steps[this.currentStepIndex];
    this.tutorialContentSubject.next(step.content);
    this.tutorialTitleSubject.next(step.title);
    this.tutorialHighlightTypeSubject.next(step.highlightType);
    
  }

  getCurrentStep(): TutorialStep {
    return this.steps[this.currentStepIndex];
  }

  // Get current step index
  getCurrentStepIndex(): number {
    return this.currentStepIndex;
  }

  getSteps(): TutorialStep[] {
    return this.steps;
  }

  navigateToStep(stepIndex: number) {
    const step = this.steps[stepIndex];
    if (step.route) {
      let path = step.route;
      let queryParams: any = {};
  
      // Check if the route contains query parameters
      if (step.route.includes('?')) {
        const [routePath, query] = step.route.split('?');
        path = routePath;
  
        query.split('&').forEach(param => {
          const [key, value] = param.split('=');
          queryParams[key] = decodeURIComponent(value); // Decode value
        });

      const currentUrl = this.router.url.split('?')[0];
      const newUrl = path;
  
      if (currentUrl !== newUrl || JSON.stringify(this.router.parseUrl(this.router.url).queryParams) !== JSON.stringify(queryParams)) {
        this.router.navigate([path], { queryParams });
      }

      } else if (step.route.includes('=')) {
        const [routePath, query] = step.route.split('=');
        path = routePath;
  
        queryParams['search'] = decodeURIComponent(query);

        this.router.navigate(['/query'], { queryParams: { search: decodeURIComponent(query) } });
      }
      else{
        this.router.navigate([step.route]);
      }
  
    }
  
    // Update tutorial content
    this.tutorialContentSubject.next(step.content);
    this.tutorialTitleSubject.next(step.title);
    this.tutorialHighlightTypeSubject.next(step.highlightType);
  }
  

  openModal() {
    console.log("In service")
    this.setHighlightedElement(1);
    this.tutorialVisibleSubject.next(true);
    this.modalSubject.next(true);
    this.navigateToStep(0);
    console.log(this.tutorialVisibleSubject);
  }

  closeModal() {
    this.modalSubject.next(false);
    this.resetHighlightedElement();
    this.tutorialVisibleSubject.next(false);
    this.tutorialHighlightTypeSubject.next("0");
    console.log(this.tutorialVisibleSubject);
    this.highlightedElementSubject.next(0);
    this.currentStepIndex = 0;
  }

  setHighlightedElement(elementIndex: number) {
    this.highlightedElementSubject.next(elementIndex);
  }

  resetHighlightedElement() {
    this.highlightedElementSubject.next(0);
  }
}
